export const KEYSTORE_NEW_PASSWORD_SET = 'KEYSTORE_NEW_PASSWORD_SET';
export const CHANGE_KEYSTORE_MODAL_SHOW = 'CHANGE_KEYSTORE_MODAL_SHOW';
export const CHANGE_KEYSTORE_MODAL_HIDE = 'CHANGE_KEYSTORE_MODAL_HIDE';
export const CHANGE_KEYSTORE_MODAL_NEXT = 'CHANGE_KEYSTORE_MODAL_NEXT';
export const CHANGE_KEYSTORE_NEW_PASSWORD_MODAL_SHOW = 'CHANGE_KEYSTORE_NEW_PASSWORD_MODAL_SHOW';
export const CHANGE_KEYSTORE_NEW_PASSWORD_MODAL_HIDE = 'CHANGE_KEYSTORE_NEW_PASSWORD_MODAL_HIDE';
export const CHANGE_KEYSTORE_NEW_PASSWORD_MODAL_NEXT = 'CHANGE_KEYSTORE_NEW_PASSWORD_MODAL_NEXT';
export const CHANGE_KEYSTORE_RESULT_MODAL_SHOW = 'CHANGE_KEYSTORE_RESULT_MODAL_SHOW';
export const CHANGE_KEYSTORE_RESULT_MODAL_HIDE = 'CHANGE_KEYSTORE_RESULT_MODAL_HIDE';
export const CHANGE_KEYSTORE_RESULT_SET = 'CHANGE_KEYSTORE_RESULT_SET';
export const CHANGE_KEYSTORE_SET_COIN_TYPE = 'CHANGE_KEYSTORE_SET_COIN_TYPE';
