export const lightTheme = {
  bodyBackground: "#F2F4F8",
  navigationBackground: "#ffffff",
  navItemActiveColor: "#e50913",
  tableBackground: "#ffffff",
  tableBorderColor: "#DDE1E6",
  selectItemBorderColor: "#E3E5F4",
  activeTabTextColor: "#272931",
  activeTabBorderColor: "#C73238",
  infoHeadingColor: "#212529",
  lineValueColor: "#2E3239",
  labelColor: "#2E3239",
  modalHeaderColor: "#313238",
  tableRowBorder: "#e0e0e0",
  paginationIconColor: "#757575",
  paginationDisabledIconColor: "rgba(0, 0, 0, 0.26)",
  infoBoxBorder: "#EAEBF6",
  votingPowerColor: "#272931",
  inputBackground: "#F5F6FA",
  refreshButtonColor: "#6c757d",
  modalBorderColor: "#E3E5F4",
  backButtonBgColor: "#F0F1F2",
  profileDropDownBorder: "#EAEBF6",
  noteBackground: "#FEF5F6",
  noteFontColor: "#2E3239",
  copyIconColor: "#2E3239",
  feeBoxBackground: "#ffffff",
  selectIconColor: "rgba(0, 0, 0, 0.54)",
  closeIconColor: "#0F0F0F",
  borderColor: "#DDE1E6",
  buttonSecondary: "#0F0F0F",
  modalContent: "#FEFEFE",
  modalCloseButton: "#0F0F0F",
  dropdownItemColor: "#2F2F2F"
};

export const darkTheme = {
  bodyBackground: "#1F2023",
  navigationBackground: "#222325",
  navItemActiveColor: "#ffffff",
  tableBackground: "#27272c",
  tableBorderColor: "#2f2f32d9",
  selectItemBorderColor: "#212529",
  activeTabTextColor: "#ffffff",
  activeTabBorderColor: "#ffffff",
  infoHeadingColor: "#8D9CB5",
  lineValueColor: "#94979b",
  labelColor: "#8D9CB5",
  modalHeaderColor: "#2E3239",
  tableRowBorder: "#4244465c",
  paginationIconColor: "#e9ecef",
  paginationDisabledIconColor: "#e9ecef7d",
  infoBoxBorder: "#4244465c",
  votingPowerColor: "#94979b",
  inputBackground: "#2e3239",
  refreshButtonColor: "#8D9CB5",
  modalBorderColor: "#94979b52",
  backButtonBgColor: "#495057",
  profileDropDownBorder: "#4244465c",
  noteBackground: "#2e3239",
  noteFontColor: "#e50913a1",
  copyIconColor: "#8D9CB5",
  feeBoxBackground: "#2e3239",
  selectIconColor: "#94979b",
  closeIconColor: "#FCFCFC",
  borderColor: "#DDE1E6",
  buttonSecondary: "#FCFCFC",
  modalContent: "#27272c",
  modalCloseButton: "#94979b",
  dropdownItemColor: "#94979b"
};
