export const LOCALSTORAGE = {
    LOGIN_INFO: "loginInfo",
};

export const COIN_ATOM = 'ATOM';
export const COIN_ATOM_DENOM = 'uatom';
export const COIN_PSTAKE = 'PSTAKE';
export const COIN_GRAVITY = 'GRAVITON';
export const COIN_GRAVITY_DENOM = 'ugraviton';
export const COIN_OSMO = 'OSMO';
export const COIN_OSMO_DENOM = 'uosmo';
