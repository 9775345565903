export const TX_RESULT_MODAL_SHOW = "TX_RESULT_MODAL_SHOW";
export const TX_RESULT_MODAL_HIDE = "TX_RESULT_MODAL_HIDE";
export const SET_TX_NAME = "SET_TX_NAME";
export const SET_TX_INFO = "SET_TX_INFO";
export const SET_LOGIN_INFO = "SET_LOGIN_INFO";
export const TX_IN_PROGRESS = "TX_IN_PROGRESS";
export const TX_IN_PROGRESS_CLOSE = "TX_IN_PROGRESS_CLOSE";
export const TX_SUCCESS = "TX_SUCCESS";
export const TX_FAILED = "TX_FAILED";
export const TX_RESPONSE = "TX_RESPONSE";
