export const CANCEL_UNBOND_DELEGATIONS_FETCH_SUCCESS =
  "CANCEL_UNBOND_DELEGATIONS_FETCH_SUCCESS";
export const CANCEL_UNBOND_DELEGATIONS_FETCH_ERROR =
  "CANCEL_UNBOND_DELEGATIONS_FETCH_ERROR";
export const CANCEL_UNBOND_DELEGATIONS_LIST = "CANCEL_UNBOND_DELEGATIONS_LIST";
export const CANCEL_UNBOND_DELEGATIONS_FETCH_IN_PROGRESS =
  "CANCEL_UNBOND_DELEGATIONS_FETCH_IN_PROGRESS";

export const TX_CANCEL_UNBOND_MEMO_SET = "TX_CANCEL_UNBOND_MEMO_SET";
export const TX_CANCEL_UNBOND_AMOUNT_SET = "TX_CANCEL_UNBOND_AMOUNT_SET";
export const TX_CANCEL_UNBOND_TO_ADDRESS_SET =
  "TX_CANCEL_UNBOND_TO_ADDRESS_SET";
export const TX_CANCEL_UNBOND_MODAL_SHOW = "TX_CANCEL_UNBOND_MODAL_SHOW";
export const TX_CANCEL_UNBOND_MODAL_HIDE = "TX_CANCEL_UNBOND_MODAL_HIDE";
