export const FETCH_ACTIVE_VALIDATORS_SUCCESS =
  "FETCH_ACTIVE_VALIDATORS_SUCCESS";
export const FETCH_INACTIVE_VALIDATORS_SUCCESS =
  "FETCH_INACTIVE_VALIDATORS_SUCCESS";
export const FETCH_VALIDATORS_ERROR = "FETCH_VALIDATORS_ERROR";
export const FETCH_VALIDATORS_SUCCESS = "FETCH_VALIDATORS_SUCCESS";
export const FETCH_VALIDATORS_IN_PROGRESS = "FETCH_VALIDATORS_IN_PROGRESS";
export const FETCH_DELEGATED_VALIDATORS_SUCCESS =
  "FETCH_DELEGATED_VALIDATORS_SUCCESS";
export const FETCH_VALIDATOR_WITH_ADDRESS_SUCCESS =
  "FETCH_VALIDATOR_WITH_ADDRESS_SUCCESS";
export const VALIDATOR_TX_MODAL_SHOW = "VALIDATOR_TX_MODAL_SHOW";
export const VALIDATOR_TX_MODAL_NAME = "VALIDATOR_TX_MODAL_NAME";
export const VALIDATOR_TX_MODAL_HIDE = "VALIDATOR_TX_MODAL_HIDE";
export const SET_VALIDATOR_TX_DATA = "SET_VALIDATOR_TX_DATA";
export const SET_VALIDATOR_DELEGATIONS = "SET_VALIDATOR_DELEGATIONS";
export const SET_VALIDATOR_REWARDS = "SET_VALIDATOR_REWARDS";
